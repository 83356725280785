import React, { useEffect, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const NewYearC = () => {
  const [curr, setCurr] = useState(0);
  const [autoSlideInterval, setAutoSlideInterval] = useState(true);
  const [slideInterval, setSlideInterval] = useState(5000);

  const slides = [
    "/images/IMG-20250103-WA0001.jpg",
    "/images/IMG-20250103-WA0002.jpg",
    "/images/IMG-20250103-WA0003.jpg",
    "/images/IMG-20250103-WA0004.jpg",
    "/images/IMG-20250103-WA0005.jpg",
  ];

  console.log(curr);

  const beforeSlide = () => {
    setCurr((curr) => (curr === 0 ? slides.length - 1 : curr - 1));
  };

  const nextSlide = () => {
    setCurr((curr) => (curr === slides.length - 1 ? 0 : curr + 1));
  };

  useEffect(() => {
    if (!autoSlideInterval) return;
    const handleSlide = setInterval(nextSlide, slideInterval);
    return () => clearInterval(handleSlide);
  }, [autoSlideInterval, slideInterval]);

  return (
    <div className="px-4 flex justify-center items-center">
      <div>
        <div className="flex max-w-[600px] h-[350px] max-md:h-auto overflow-hidden relative group ">
          <div className="max-w-[600px] h-auto">
            <img src={slides[curr]} alt="" className="w-full object-cover" />
          </div>

          <div className="absolute justify-between flex opacity-0 w-full h-full items-center p-2 group-hover:opacity-100 transition-all duration-300">
            <span
              className="bg-white/80 shadow text-gray-800/80 hover:bg-white hover:text-gray-800 rounded-full flex justify-center items-center transition-all duration-300 hover:scale-110 hover:translate-x-1 cursor-pointer "
              onClick={() => beforeSlide()}
            >
              <FaAngleLeft size={30} />
            </span>
            <span
              className="bg-white/80 shadow text-gray-800/80 hover:bg-white hover:text-gray-800 rounded-full flex justify-center items-center transition-all duration-300 hover:scale-110 hover:-translate-x-1 cursor-pointer"
              onClick={() => nextSlide()}
            >
              <FaAngleRight size={30} />
            </span>
          </div>

          <div className="absolute bottom-0 right-0 left-0 flex  bg-black/30 p-2 w-full  justify-center items-center gap-3  transition-all duration-300">
            {slides.map((_, index) => (
              <div
                className={`size-2 bg-white rounded-full transition-all duration-300 cursor-pointer ${
                  curr === index ? "p-1.5" : "bg-opacity-60"
                } `}
                onClick={() => setCurr(index)}
                key={index}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewYearC;
